<template>
  <div class="container" style="padding-top: 25px; padding-bottom: 15px">
    <h1 class="title-custom">{{ category.name }}</h1>
    <nav class="breadcrumb" aria-label="breadcrumbs" style="margin-top: 1.5rem">
      <ul>
        <li><router-link to="/">Интернет-Магазин</router-link></li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ category.name }}</a>
        </li>
      </ul>
    </nav>
    <div class="filter">
      <div class="filter-title">Фильтр:</div>
      <b-dropdown
        aria-role="list"
        v-for="item in filterNames"
        :key="item.name"
        :triggers="['hover', 'click']"
      >
        <template #trigger="{ active }">
          <b-button
            :label="item.name"
            size="is-small"
            :icon-right="active ? 'caret-up' : 'caret-down'"
          />
        </template>

        <b-dropdown-item
          aria-role="listitem"
          v-for="val in item.values"
          :key="val.value"
        >
          <b-checkbox
            v-model="filterGroup"
            :native-value="{ name: item.name, value: val, ids: item.ids }"
            @input="applyFilter()"
            >{{ val.value }}</b-checkbox
          ></b-dropdown-item
        >
      </b-dropdown>
      <b-button
        size="is-small"
        @click="clearFilter()"
        icon-left="trash-alt"
        style="margin-left: 0.5rem"
        >Сбросить фильтр</b-button
      >
    </div>
    <div class="items-list">
      <div class="items-item" v-for="item in filteredData" :key="item.id">
        <div class="product-tags" style="z-index: 10">
          <b-tag
            v-if="item.is_new"
            type="is-warning"
            style="margin-right: 10px"
          >
            б\у
          </b-tag>
          <b-tag
            v-if="item.is_top"
            type="is-warning"
            style="background: #837ea1"
            >хит продаж</b-tag
          >
        </div>
        <router-link :to="{ name: 'product', params: { alias: item.alias } }">
          <div class="photo">
            <img :src="uploads_url + item.photo_url" alt="pic" />
          </div>
          <p class="name">{{ item.name }}</p>
          <p
            style="
              text-align: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
            "
          >
            <star-rating
              :star-size="15"
              :read-only="true"
              :show-rating="false"
              v-model="item.rating"
            ></star-rating>
          </p>
          <p class="price">{{ item.price }} ₽</p>
          <b-button type="is-warning" size="is-small" icon-left="shopping-cart"
            >Купить</b-button
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.items-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.items-item {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 25%;
  transition: all 0.1s ease-in-out;
  border: 1px #ecf0f1 solid;
  border-left: 0;
  border-top: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  &:nth-child(1),
  &:nth-child(5n) {
    border-left: 1px #ecf0f1 solid;
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    border-top: 1px #ecf0f1 solid;
  }
  &:hover img {
    opacity: 0.8;
  }
  .photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    overflow: hidden;
    img {
      max-width: 200px;
    }
  }
  .name {
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    color: #1d2029;
  }
  .price {
    font-size: 18px;
    line-height: 36px;
    font-weight: 700;
    color: #1d2029;
  }
  .product-tags {
    position: absolute;
    left: 1rem;
  }
}
@media screen and (min-width: 900px) {
  .container {
    width: 1344px;
  }
}
@media screen and (max-width: 900px) {
  .items-list {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .items-item {
    width: 100%;
    margin: 0;
    max-width: 100%;
    margin-bottom: 3rem;
  }
  .container {
    width: 100%;
  }
}
.items-item:nth-child(4n) {
  margin-right: 0;
}
.items-item:hover a {
  color: #f7a31d;
}
.items-item img {
  width: 100%;
}
.items-item a {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}
.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  line-height: 35px;
}
.filter-title {
  font-weight: bold;
  margin-right: 1rem;
  font-size: 14px;
}
</style>

<script>
import { HTTP } from "../../http-common";
import StarRating from "vue-star-rating";

export default {
  components: { StarRating },
  data() {
    return {
      uploads_url: process.env.VUE_APP_IMAGES_SERVER_URL,
      category: { name: "loading..." },
      data: [],
      filteredData: [],
      loading: false,
      filterProps: [],
      filterNames: [],
      filterValues: [],
      filterGroup: [],
    };
  },
  methods: {
    async loadCategoryData() {
      await HTTP.get("/category/" + this.$route.params.alias).then((res) => {
        this.category = res.data;
      });
    },
    loadProductList() {
      this.loading = true;
      HTTP.get("/product?category=" + this.category.id).then((res) => {
        this.loading = false;
        this.data = res.data;
        this.filteredData = res.data;
      });
    },
    loadFilterProps() {
      HTTP.get("/shop/filter/" + this.category.id).then((res) => {
        this.filterProps = res.data;
        let filterNames = [];
        if (this.filterProps == null) return;
        this.filterProps.forEach((i) => {
          if (!filterNames.includes(i.name)) {
            filterNames.push(i.name);
            let prop = { name: i.name, values: [] };
            let values = [];
            this.filterProps.forEach((j) => {
              if (j.name == i.name && !values.includes(j.value)) {
                values.push(j.value);
                let ids = [];
                this.filterProps.forEach((f) => {
                  if (f.name == i.name && f.value == j.value) {
                    ids.push(f.product_id);
                  }
                });
                prop.values.push({ value: j.value, ids: ids });
              }
            });
            this.filterNames.push(prop);
          }
        });
      });
    },
    filterPropsByName(name) {
      return this.filterProps.filter((i) => i.name == name);
    },
    applyFilter() {
      if (this.filterGroup.length == 0) {
        this.filteredData = this.data;
        return;
      }
      let ids = [];
      this.filterGroup.forEach((i) => {
        ids = ids.concat(i.value.ids);
      });
      this.filteredData = this.data.filter((product) => {
        return ids.includes(product.id);
      });
      console.log(this.filterGroup);
    },
    clearFilter() {
      this.filteredData = this.data;
      this.filterGroup = [];
    },
  },
  async created() {
    await this.loadCategoryData();
    this.loadProductList();
    this.loadFilterProps();
  },
};
</script>
